/* Light Purple */
/* background-color: rgb(200, 136, 204); */

/* Dark Purple */
/* background-color: rgb(122, 73, 140); */

/* Light Tan */
/* background-color: rgb(198, 186, 175); */

/* Dark Tan */
/* background-color: rgb(182, 162, 146); */

/* Red */
/* background-color: rgb(213, 54, 62); */

/* Light Orange */
/* background-color: rgb(199, 80, 36); */

/* Dark Orange */
/* background-color: rgb(142, 56, 34); */

/* Yellow */
/* background-color: rgb(255, 190, 49); */

/* Green */
/* background-color: rgb(63, 130, 38); */

/* Light Blue */
/* background-color: rgb(157, 207, 212); */

/* Dark Blue */
/* background-color: rgb(48, 107, 127); */

body {
  background-color: #2c2c2c;
}

.TextOutline {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.App {
  position: absolute;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  text-align: center;
  background: url("./assets/images/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.Curtain-NestedElement {
  height: calc(100% * (1 - 232 / 980 * 2));
  width: calc(100% * (1 - 232 / 980 * 2));

  top: calc(100% * (232 / 980));
  left: calc(100% * (232 / 980));
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.Title {
  margin-top: 0;
  margin-bottom: 40px;
  letter-spacing: 2px;
  color: rgb(255, 190, 49);
  font-size: 30px;
}

.Title-wallet {
  margin-bottom: 40px;
  font-size: 20px;
}

.Wallet-section {
  max-width: 100%;
  width: 400px;
  margin-top: 180px;
  padding: 40px 20px;

  background-color: rgb(122, 73, 140);
  border: 8px solid #000;
  border-radius: 10px;
}

.ConnectMetamaskTitle {
  margin-top: 0;
  margin-bottom: 40px;
}

.Section-container {
  display: flex;
  max-width: 100%;
  width: 1000px;
  margin-top: 80px;
  padding: 120px 40px 60px;

  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.SectionPanel {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(48% - 20px - 16px);

  border: 8px solid #000;
  border-radius: 10px;
  padding: 50px 10px 20px;
}

.SectionPanel-left {
  background-color: rgb(48, 107, 127);
}

.SectionPanel-right {
  background-color: rgb(199, 80, 36);

  justify-content: center;
  flex-direction: column;
}

.CheckTitle {
  margin-top: 0;
  color: rgb(255, 190, 49);
  width: 100%;
}

.CheckTitle-claim {
  margin-bottom: 0;
}

.CheckTitle-wallet {
  font-weight: 100;
  margin-bottom: 30px;
}

.BearIdTitle {
  width: 190px;
  margin: 0 10px;
}

.BearIdTitle .MuiOutlinedInput-root {
  background-color: white;
  font-size: 26px;
  letter-spacing: 10px;
  border-radius: 6px;
}

.BearIdTitle .MuiInputLabel-outlined.MuiInputLabel-shrink {
  display: none;
}

.BearIdTitle
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  width: 190px;
  left: -14px;
  top: 0px;
  font-size: 24px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 40%);
}

.BearIdTitle .MuiOutlinedInput-notchedOutline {
  border: none;
}

.BearIdTitle .MuiInputBase-input.MuiOutlinedInput-input {
  text-align: center;
  padding-top: 18px;
  padding-bottom: 12px;
}

.ClaimBear-section {
  margin: 0;
}

.ClaimBear-text {
  margin: 10px 0 0;
  font-size: large;
}

a.ChooseBackgroundLink {
  color: rgb(255, 190, 49);
}

.Selector-section,
.SelectorList-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectorList-container {
  height: 412px;
  max-height: 412px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.SelectorList-container.SelectorList-loading_adjust {
  height: 390px;
  max-height: 390px;
}

.SelectorList-section {
  width: 100%;
  height: 100%;
}

.SelectorList-element {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px 0;
  margin-bottom: 10px;
  background-color: white;
}

.SelectorList-element.SelectorList-selected {
  background-color: rgb(182, 162, 146);
}

.SelectorList-title {
  margin: 0px;
  margin-left: 20px;

  font-size: 25px;
  font-weight: 900;
  letter-spacing: 3px;
  color: black;
}

.SelectorList-selected .SelectorList-title {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.SelectorList-text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SelectorList-mini-text {
  margin: 10px;
  color: rgb(63, 130, 38);
}

.SelectorList-added {
  margin-left: 18px;
}

.Subtitle-section {
  margin-top: 80px;
  display: flex;
  width: 100%;
}
.Subtitle-container {
  display: flex;
  width: 50%;
}

.Supply-text {
  text-align: left;
  margin-right: auto;
}
.Price-text {
  text-align: right;
  margin-left: auto;
}

.image-18 {
  width: 135px;
}

.image-dropbears-logo {
  width: 102px;
}

.social-icon {
  width: 50px;
  padding-left: 20px;
}

.Footer-container {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 140px;
  padding: 40px 10%;
  background-color: rgba(0, 0, 0, 70%);
}

.Footer-section {
  display: flex;
  align-items: center;
  width: 33.33333333%;
  margin-bottom: 50px;
}

.Text-center {
  justify-content: center;
}

.Text-right {
  justify-content: flex-end;
}

.text-purchase {
  margin-top: 40px;
  font-family: "Droid Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.MuiButton-contained.Mui-disabled.SelectBackgroundButton {
  background-color: transparent;
}

.MuiButton-contained.SelectBackgroundButton,
.MuiButton-contained.SelectBackgroundButton:hover,
.MuiButton-contained.SelectBackgroundButton:focus {
  position: relative;
  margin: 0 10px !important;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  font-size: 24px;
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.MiddleButtonImage {
  height: 70px;
}

.SmallButtonImage {
  height: 40px;
}

.MuiButton-contained.ConnectMetamaskButton {
  margin-left: 10px !important;
  background-color: rgb(255, 190, 49);
  color: #000;
}

.MiddleButtonText {
  position: absolute;
  display: flex;
}

.MuiSelect-outlined.MuiSelect-outlined,
.MuiSelect-select:focus {
  background-color: white !important;
  border-radius: 4px;
}

.MuiFormControl-root {
  min-width: 180px !important;
}

.WalletText {
  font-size: 18px;
  margin-bottom: 140px;
  color: rgb(255, 190, 49);
}

.WalletText-reduced {
  margin-bottom: 20px;
}

.LoadingText {
  font-size: 18px;
  color: rgb(255, 190, 49);
  margin: 0;
}

.Selector-CheckSection {
  margin-bottom: 10px;
}

.Selector-ToggleSection {
  height: 100%;
}

.SectionListElement-container {
  position: relative;
  width: calc(184px * 2 + 20px + 3px * 2);
  margin: auto;
  padding: 0 10px;
}

.SectionListElement-image {
  height: calc(184px * 2 + 20px + 3px * 2);
}

.SectionList-placeholder {
  margin-top: 160px;
}

.BackgroundImage-element,
.BearImage-element {
  width: calc(100% - 2px * 2);
  border-radius: 10px;
  border: 2px solid black;
}

.BearImage-element {
  position: absolute;
  left: 10px;
  height: calc(100% - 2px * 2);
  top: 0;
  width: calc(100% - 12px * 2);
}

.BearText-status {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 10px;

  height: 40px;
  width: calc(80% - 34px);
  margin: 10px 10% 0;
  padding: 5px;
  color: white;

  border-radius: 10px;
  border: 2px solid black;

  text-transform: capitalize;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 5px;
}

.BearText-status-claimed {
  background-color: rgb(213, 54, 62);
}

.BearText-status-available {
  background-color: rgb(255, 190, 49);
}

.MuiInputBase-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 40%) !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(255, 255, 255, 70%) !important;
}

@media only screen and (max-width: 1042px) {
  .Section-container {
    flex-direction: column;
    align-items: center;
  }

  .SectionPanel {
    width: 438px;
  }

  .SectionPanel-left {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 912px) {
  .Footer-container {
    margin-top: 140px;
    flex-direction: column;
    align-items: center;
  }

  .Footer-section {
    justify-content: center;
  }

  .social-icon {
    padding: 0 40px;
  }
}
